<template>
  <div class="main-styles">
    <p>
      兵乓球<br />一般规则<br />1、所有注单仅以赛事结束方可视为有效，除非另有特别说明。
    </p>
    <p>2、如果比赛持续时间缩短或比赛要求提高获胜比分，所有注单将被视为无效。</p>
    <p>3、如果赛事延期或中断，只要比赛最后有完成，所有投注将保持有效。</p>
    <p>
      4、如果比赛提前开赛，仅有在开赛之前投注的注单将为视为有效投注。开赛后投注的注单将被视为无效，
      滚球玩法除外。
    </p>
    <p>
      / 优胜者<br />1、预测哪个球员将会赢取比赛的胜利。这个比赛包含双方球员。
    </p>
    <p>
      <br />让盘 / 让局<br />1、预测哪一位球员在盘口指定的让盘/局数赢得比赛。
    </p>
    <p>
      <br />盘/局 - 大/小<br />1、预测比赛进行的总盘/局数是大于或小于盘口指定的大小盘局数。
    </p>
    <p>
      <br />让分盘<br />1、预测在某个时节 哪一位球员在盘口指定的让分数赢得比赛。
    </p>
    <p>
      <br />总得分 - 大/小<br />1、预测提名的球员在某一特定的时间段内的个人总得分将大于或小于盘口指定的总分数线
    </p>
    <p>
      <br />&nbsp;球员/球队得分 -大/小<br />1、预测主场或客场的总比分是大于还是小于指定盘口。
    </p>
    <p>
      2、如果赛事中断，除非市场已经派彩或任何将来的得分不会影响到赛果的投注有效，其他所有注单一律视为无效。
    </p>
    <p><br />&nbsp;波胆<br />1、预测一个特定赛事全场的正确比分。</p>
    <p>2、结算将依据相关部门最终公布的比分为准。</p>
    <p>
      <br />&nbsp;单/双<br />1、预测在某一特定的时间段内比赛进行的总局数、盘数或分数是单数还是双数。<br />
    </p>
  </div>
</template>
